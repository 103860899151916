import React from 'react';
import {SectionOneC, SectionOneContainer, FbFeed, TextContainer,TextP} from './SectionOneElements';
import {FacebookProvider, Page} from 'react-facebook';

function SectionOne() {
    return (
        <SectionOneC>
            <SectionOneContainer>
                <FbFeed>
                    <FacebookProvider appId='{process.env.REACT_APP_FACEBOOK_KEY}'>
                        <Page href='https://www.facebook.com/ockerorcklubb' tabs='timeline'/>
                    </FacebookProvider>
                </FbFeed>
                <TextContainer>
                    <TextP>Följ oss på facebook för regelbunden uppdatering!</TextP>
                </TextContainer>
            </SectionOneContainer>
        </SectionOneC>
    )
}

export default SectionOne 
