import styled from 'styled-components'

export const SectionOneC = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
`

export const SectionOneContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`

export const FbFeed = styled.div`
    margin: 20px;

    @media screen and (max-width:768px){
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
    }
`

export const TextContainer = styled.div`
    width: 350px;
    margin: 20px;

    @media screen and (max-width:768px){
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 0;
        margin-right: 0;
    }
`

export const TextP = styled.p`
    font-size: 36px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (max-width: 768px){
        font-size:24px;
    }
`