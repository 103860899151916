import React from 'react'
import FaqSection from '../Components/Vanligafragor'

function Vanligafragor() {
    return (
        <>
            <FaqSection/>
        </>
    )
}

export default Vanligafragor
