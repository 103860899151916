import React from 'react'
import FB_Icon from '../_assets/FB-icon.svg'
import YT_Icon from '../_assets/YT-icon.svg'
import IG_Icon from '../_assets/IG-icon.svg'
import {
    Footer,
    FooterContainer,
    SocialsContainer,
    SocialLink,
    SocialP,
    CopyContainer,
    CopyP,
} from './FooterElements';

function Footerbar() {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener, noreferrer')
        if (newWindow) newWindow.opener = null
    };

    const FB_Img = <img src={FB_Icon} alt ='' onClick={() => openInNewTab('https://www.facebook.com/ockerorcklubb')}/>;
    const YT_Img = <img src={YT_Icon} alt ='' onClick={() => openInNewTab('https://www.youtube.com/channel/UCF5MUeX5IJGRz3D_BEZ3S2g')}/>;
    const IG_Img = <img src={IG_Icon} alt ='' onClick={() => openInNewTab('https://www.instagram.com/rc_ockero/')}/>;

    return (
        <Footer>
            <FooterContainer>
                <SocialsContainer>
                    <SocialP>Ni hittar oss också på</SocialP>
                    <SocialLink>{FB_Img}</SocialLink>
                    <SocialLink>{YT_Img}</SocialLink>
                    <SocialLink>{IG_Img}</SocialLink>
                </SocialsContainer>
                <CopyContainer>
                    <CopyP>Copyright © Öckerö RC Klubb</CopyP>
                </CopyContainer>
            </FooterContainer>
        </Footer>
    )
}

export default Footerbar
