import React from 'react'
import {FaqContainer, 
    FaqContent, 
    FaqH, 
    FaqDesc, 
    FragaQ, 
    FragaA,
    FragaAContainer, 
    FragaSlut
    } from './VanligafragorElements'

function FaqSection() {
    return (
        <FaqContainer>
            <FaqContent>
                <FaqH>Vanliga frågor</FaqH>
                <FaqDesc>

                    <FragaQ>Vad ska jag köpa för märke?</FragaQ>
                    <FragaA>
                        Kolla med den klubb som du avser köra mest på! 
                        Vi rekommenderar att du köper det märke som är mest representerat på klubben, 
                        eftersom det sannolikt finns mycket erfarenheter kring just det märket. 
                    </FragaA>

                    <FragaQ>Är det en dyr sport?</FragaQ>
                    <FragaA>
                        Som inom andra sporter varierar utgifterna. 
                        En hobbyförare behöver inte alla extra delar som en proffsförare har utrustat sin bil med. 
                        Det går alldeles utmärkt att utvecklas och ha kul med originalbilen.
                    </FragaA>

                    <FragaQ>Vad gäller på ett banområde?</FragaQ>
                    <FragaAContainer>
                        <FragaA>Håll rent, plocka undan efter dig och var trevlig! Men framför allt, ha roligt!</FragaA>
                        <FragaA>- Kör i rätt riktning. Om du är ensam på banan och är osäker: Fråga!!</FragaA>
                        <FragaA>- Håll dig inom banmarkeringarna (så gott det går).</FragaA>
                        <FragaA>- Släpp förbi snabbare bilar.</FragaA>
                        <FragaA>- Stanna aldrig mitt i spåret, utan åk åt sidan om du behöver stanna!</FragaA>
                        <FragaA>
                            - Om du oavsiktligt råkat hamna mitt i spår och inte kommer därifrån säg snabbt till övriga förare att du ligger i vägen! 
                            Exempelvis "Bil på rakan!!" om du råkar ligga mitt på raksträckan. Det kommer att spara många reservdelar och sura miner om du lyckas förhindra krockar.
                        </FragaA>
                        <FragaA>
                            - Om du behöver ut och vända din bil (om den ligger på taket), se till att göra detta utan att skymma sikten för andra. 
                            Kolla också var andra bilar är innan du kliver ut på banan! 
                        </FragaA>
                        <FragaA>- Vårda ditt språkbruk på förarställningen. Vi har många barn i klubben.</FragaA>
                    </FragaAContainer>


                    <FragaSlut>
                        Har du fler frågor eller är osäker? Kom gärna förbi på träningar eller mejla support@ockerorcklubb.se så hjälper vi dig gärna!
                    </FragaSlut>

                </FaqDesc>
            </FaqContent>
        </FaqContainer>
    )
}

export default FaqSection
