import React from 'react'
import {
    MedlemskapContainer,
    MedlemskapContent,
    MedlemskapH,
    MedlemskapDesc,
    MedlemskapP,
    MedlemskapPB,
    MedlemskapLi
} from './MedlemskapElements'

function MedlemskapSection() {
    return (
        <MedlemskapContainer>
            <MedlemskapContent>
                <MedlemskapH>Medlemskap</MedlemskapH>
                <MedlemskapDesc>
                    <MedlemskapP>
                    Bli medlem genom att mejla till följande med; Namn, personnummer, adress, telefonnummer och typ av medlemskap till support@ockerorcklubb.se. 
                    Betala in medlemsavgiften så kommer bekräftelse på medlemskap så fort de har behandlats. 
                    </MedlemskapP>
                    <MedlemskapLi>Ungdom: till och med året man fyller 18 år 500 kronor/år, ingen Track-fee.</MedlemskapLi>
                    <MedlemskapLi>Senior: över 18 år 1000 kronor/år, ingen Track-fee.</MedlemskapLi>
                    <MedlemskapLi>Familj: 1300 kronor/år, ingen Track-fee.</MedlemskapLi>
                    <MedlemskapLi>Stödmedlem: 300 kronor/år, får köra men måste betala Track-fee.</MedlemskapLi>
                    <MedlemskapLi>Medlemsavgiften skall vara betald senast 1/1</MedlemskapLi>

                    <MedlemskapPB>Vid Träning</MedlemskapPB>
                    <MedlemskapLi>Nybörjare får köra 2 gånger innan träningsavgift betalas in. </MedlemskapLi>
                    <MedlemskapLi>Träningsavgiften / Track-fee skall vara betald innan påbörjad körning.</MedlemskapLi>
                    <MedlemskapLi>Det går bra att betala med följande tjänster:</MedlemskapLi>
                    <MedlemskapLi>Bankgiro: Kommer snart</MedlemskapLi>
                    <MedlemskapLi>Swish: Kommer snart</MedlemskapLi>
                    <MedlemskapLi>VIKTIGT! Ange namn och typ av medlemskap/träning vid inbetalning.</MedlemskapLi>
                    
                    <MedlemskapPB>Trackfee</MedlemskapPB>
                    <MedlemskapLi>Avgifter för icke medlemmar och stödmedlemmar asfaltsbanan/Innebanan.</MedlemskapLi>
                    <MedlemskapLi>Asfaltsbanan: 200 kronor/dag</MedlemskapLi>
                    <MedlemskapLi>Innebanan: 200 kronor/dag</MedlemskapLi>
                </MedlemskapDesc>
            </MedlemskapContent>
        </MedlemskapContainer>
    )
}

export default MedlemskapSection
