import React from 'react'
import {
    SectionThreeContainer,
    MapContainer,
    MapPicture
} from './SectionThreeElements'
import Karta from '..//_assets/Karta.png'

function SectionThree() {
    const Mapimage = <img src={Karta} alt=''></img>;

    return (
    <SectionThreeContainer>
        <MapContainer>
            <MapPicture>{Mapimage}</MapPicture>
        </MapContainer>    
    </SectionThreeContainer>
    )
}

export default SectionThree
