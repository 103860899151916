import React from 'react'
import video from '../_assets/ockerorctest.mp4'
import { HeroContainer, 
    HeroBackground, 
    VideoBackground, 
    HeroContent, 
    HeroH1
    } from './HeroElements'

const HeroSection = () => {
    return (
        <HeroContainer>
            <HeroBackground>
                <VideoBackground autoPlay loop muted src={video} type='video/mp4' playsInline={true}/>
            </HeroBackground>
            <HeroContent>
                <HeroH1>VÄLKOMMEN TILL ÖCKERÖ RC KLUBB</HeroH1>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
