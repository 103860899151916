import styled from 'styled-components'
import img from '../_assets/omossbg.jpg'

export const OmOssContainer = styled.div`
    background-color: #353535;
    background-image: linear-gradient(0deg, rgba(26, 26, 26, 0.85), rgba(26, 26, 26, 0.85)), url(${img});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    width: 100%;
    height: 100vh;

    @media screen and (max-width:768px){
        height: auto;
    }
`;

export const OmOssContent = styled.div`
    margin-top: 5%;
    margin-bottom: 5%;
    width: 750px;
    padding: 8px 24px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    @media screen and (max-width:768px){
        margin-top: 40%;
    }
`;

export const OmOssH = styled.h1`
    color: white;
    letter-spacing: 3px;
    font-size: 72px;
    border-bottom: solid white 5px;
    margin: 50px 0 50px 0;

    @media screen and (max-width: 768px){
        font-size: 42px;
        margin: 0 0 20px 0;
    }
`;

export const OmOssDesc = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export const OmOssP = styled.p`
    font-family: 'Roboto', sans-serif;
    word-wrap: break-word;
    font-weight: 500;
    text-align: justify;
    font-size: 18px;
    color: white;
    margin-bottom: 20px;

    @media screen and (max-width: 768px){
        font-size: 16px;
        text-align: left;
    }
`;
export const OmOssLi = styled.li`
    font-family: inherit;
    list-style: none;

`;