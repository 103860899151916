import React from 'react'
import {OmOssContainer, OmOssContent, OmOssH, OmOssDesc, OmOssP, OmOssLi} from './OmOssElements'

function OmOssSection () {
    return (
        <OmOssContainer>
            <OmOssContent>
                <OmOssH>OM OSS</OmOssH>
                <OmOssDesc>
                    <OmOssP>
                        Öckerö RC Klubb startades i november 2019 och sysslar med radiostyrd bilsport. Föreningen är för alla, 
                        barn som vuxna, med ett intresse för radiostyrda bilar. Föreningen är en plats där vi kan träffas, köra, meka och ha kul med våra el-RC-bilar. 
                    </OmOssP>
                    <OmOssP>
                        Radiostyrd Bilsport (RB) är en gren inom Svenska Bilsportförbundet som sysslar med radiostyrda bilar på bana eller Off-road. 
                        Man delar in RB i klasser beroende på bilarnas storlek i förhållande till bilar i naturlig storlek och på vilket sätt bilarna drivs fram.
                    </OmOssP>
                    <OmOssP>
                        Vi har idag två banor. En inomhus och en utomhus. På våra banor gäller följande bilar:
                        <OmOssLi>Utomhus: 1:10 touring 1:10 fwd 1:12 Pan car</OmOssLi>
                        <OmOssLi>Inomhus: 1:10 El Buggy 2wd eller 4wd. 1:10 touring 1:10 fwd 1:12 Pan car</OmOssLi>
                    </OmOssP>
                    <OmOssP>
                        Är du osäker på vilken bil du har? Tveka inte att höra av dig till oss så hjälper vi dig. support@ockerorcklubb.se
                        <br/><br/>På sommaren kör vi framförallt på våran tourningbana på Brattebergsskolans skolgård.
                    </OmOssP>
                </OmOssDesc>
            </OmOssContent>
        </OmOssContainer>
    )
}

export default OmOssSection
