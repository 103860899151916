import React from 'react'
import HeroSection from '../Components/Hero';
import SectionOne from '../Components/PageContent/SectionOne'
import SectionTwo from '../Components/PageContent/SectionTwo'
import SectionThree from '../Components/PageContent/SectionThree';

function Home() {
    return (
        <>
            <HeroSection/>
            <SectionOne/>
            <SectionTwo/>
            <SectionThree/>
        </>
    )
}

export default Home
