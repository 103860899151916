import React from 'react'
import {SectionTwoContainer, 
    SponsorContainer, 
    SponsorerH1,
    LogoContainer,
    SponsorLogga1,
    SponsorLogga2,
    SponsorLogga3
} from './SectionTwoElements';
import EURC from '../_assets/eurorc.jpg';
import LATERA from '../_assets/latera.svg';
import FMTELEKRAFT from '../_assets/FMTELEKRAFT.jpg';


function SectionTwo() {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener, noreferrer')
        if (newWindow) newWindow.opener = null
    };

    const EURCimg = <img src={EURC} alt='' onClick={() => openInNewTab('https://www.eurorc.se/')}/>;
    const LATERAimg = <img src={LATERA} style={{maxWidth: "100%"}} alt='' onClick={() => openInNewTab('https://latera.se/')}/>;
    const FMTELEimg = <img src={FMTELEKRAFT} alt='' onClick={() => openInNewTab('https://www.fmtelekraft.se/index.html')}/>;

    return (
        <SectionTwoContainer>
            <SponsorerH1>Sponsorer</SponsorerH1>
            <SponsorContainer>
                <LogoContainer>
                    <SponsorLogga1>{LATERAimg}</SponsorLogga1>
                    <SponsorLogga2>{FMTELEimg}</SponsorLogga2>
                    <SponsorLogga3>{EURCimg}</SponsorLogga3>
                </LogoContainer>
            </SponsorContainer>
        </SectionTwoContainer>
    )
}

export default SectionTwo
