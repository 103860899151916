import React from 'react';
import {NavbarContainer} from '../Navbar/NavbarElements';
import FB_Icon from '../_assets/FB-icon.svg';
import YT_Icon from '../_assets/YT-icon.svg';
import IG_Icon from '../_assets/IG-icon.svg';
import {SidebarContainer, 
    MenuContainer,
    NavMenu,
    NavTitle,
    NavLinks,
    KontaktInfo,
    KontaktTitle,
    KontaktText,
    KontaktSocial,
    SocialLink,
    } from './SidebarElements';

const Sidebar = ({isOpen, toggle}) => {

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener, noreferrer')
        if (newWindow) newWindow.opener = null
    };

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <NavbarContainer/>        
            <MenuContainer>
                <NavMenu>
                    <NavTitle>
                        MENY
                    </NavTitle> 
                    <NavLinks to='/' onClick={toggle}>STARTSIDA</NavLinks>
                    <NavLinks to='Omoss' onClick={toggle}>OM OSS</NavLinks>
                    <NavLinks to='Medlemskap' onClick={toggle}>Medlemskap</NavLinks>
                    <NavLinks to='Vanligafragor' onClick={toggle}>VANLIGA FRÅGOR</NavLinks>
                </NavMenu>
                <KontaktInfo>
                    <KontaktTitle>
                        KONTAKTA OSS
                    </KontaktTitle>
                    <KontaktText>
                        SUPPORT@OCKERORCKLUBB.SE
                    </KontaktText>
                    <KontaktText>
                        BRATTEVÄGEN 34
                    </KontaktText>
                    <KontaktText>
                        475 31
                    </KontaktText>
                    <KontaktSocial>
                        <SocialLink><img src={FB_Icon} alt ='' onClick={() => openInNewTab('https://www.facebook.com/ockerorcklubb')}></img></SocialLink>
                        <SocialLink><img src={YT_Icon} alt ='' onClick={() => openInNewTab('https://www.youtube.com/channel/UCF5MUeX5IJGRz3D_BEZ3S2g')}></img></SocialLink>
                        <SocialLink><img src={IG_Icon} alt ='' onClick={() => openInNewTab('https://www.instagram.com/rc_ockero/')}></img></SocialLink>
                    </KontaktSocial>
                </KontaktInfo>
            </MenuContainer>              
        </SidebarContainer>
    )
}

export default Sidebar
