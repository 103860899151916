import React from 'react'
import OmOssSection from '../Components/OmOss'

function Omoss() {
    return (
        <>
            <OmOssSection/>
        </>
    )
}

export default Omoss
