import React from 'react'
import logo from '../_assets/Logga1.svg'
import logomobile from '../_assets/Logga2.svg'
import navbutton from '../_assets/Knapp.svg'
import navbutton_back from '../_assets/Knappclose.svg'
import {Nav, NavbarContainer, NavLogoWeb, NavLogoMobile, NavButton} from './NavbarElements';


const Navbar = ({toggle, isOpen}) => {
    
    const LogoWebImg = <img src={logo} alt=''/>;
    const LogoMobImg = <img src={logomobile} alt=''/>;
    const Button = <img src={isOpen ? navbutton_back : navbutton} onClick={toggle} alt=''/>;

    return (
        <>
            <Nav>
                <NavbarContainer>
                    <NavLogoWeb to='/'>
                        {LogoWebImg}
                    </NavLogoWeb>
                    <NavLogoMobile to='/'>
                        {LogoMobImg}
                    </NavLogoMobile>
                    <NavButton> 
                        {Button}
                    </NavButton>
                </NavbarContainer>
            </Nav>
        </>
    );
};

export default Navbar
