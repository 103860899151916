import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Pages/index';
import Medlemskap from './Pages/medlemskap';
import Omoss from './Pages/omoss';
import Vanligafragor from './Pages/vanligafragor';
import Navbar from './Components/Navbar';
import Sidebar from './Components/Sidebar';
import Footerbar from './Components/Footer';
import React, {useState} from 'react';


const App = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
      setIsOpen(!isOpen);
  };

  return (
    <Router>
      <Sidebar isOpen={isOpen} toggle={toggle}/>
      <Navbar isOpen={isOpen} toggle={toggle}/>

      <Routes>
        <Route path='/' element={<Home/>} exact/>
        <Route path='/omoss' element={<Omoss/>} exact/>
        <Route path='/medlemskap' element={<Medlemskap/>} exact/>
        <Route path='/vanligafragor' element={<Vanligafragor/>} exact/>
      </Routes>

      <Footerbar/>
    </Router>
  );
}

export default App;
