import React from 'react'
import MedlemskapSection from '../Components/Medlemskap'

function Medlemskap() {
    return (
        <>
            <MedlemskapSection/>
        </>
    )
}

export default Medlemskap
