import styled from 'styled-components'

export const SectionThreeContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: #353535;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
`

export const MapContainer = styled.div`
    display: flex;
`

export const MapPicture = styled.div`
    margin-bottom: 30px;
    object-fit: cover;
    -o-object-fit: cover;
    width: auto;
    height: auto;
`