import styled from 'styled-components'

export const SectionTwoContainer = styled.div`
    background: #353535;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
`
export const SponsorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-content: space-around;
    align-self: center;
    align-items: center;
    width: 100%;
    margin: 50px;

    @media screen and (max-width:768px){
        margin-top: 25px;
        margin-bottom: 25px;
        margin-left: 0;
        margin-right: 0;
    }

`

export const SponsorerH1 = styled.h1`
    font-size: 72px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: white;
    font-style: italic;
    margin: 50px 0px 15px 50px;

    @media screen and (max-width:768px){
        font-size: 42px;
        align-self: center;
        margin: 25px 0px 1rem 0%;
    }
`

export const LogoContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width:100%;
    align-items: center;
    justify-content: center;
`

export const SponsorLogga1 = styled.div`
    width: 600px;
    margin: 2rem;

   &:hover{
        cursor: pointer;
    }  

    @media screen and (max-width:768px){
        transform:scale(0.9)
    }
`
export const SponsorLogga2 = styled.div`
    margin: 2rem;

   &:hover{
        cursor: pointer;
    }  
`
export const SponsorLogga3 = styled.div`
    transform: scale(0.7);
    margin: 2rem;

   &:hover{
        cursor: pointer;
    }  
`



