import styled from 'styled-components';

export const Footer = styled.footer`
    background: #1F1F1F;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    //bottom:0;
`;

export const FooterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: 0 50px;
    color: white;

`;

export const SocialsContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const SocialLink = styled.div`
    margin: 10px;

    &:hover{
        cursor: pointer;
    }  

    @media only screen and (max-width: 768px){
        margin: 5px;
  }
`;


export const SocialP = styled.p`
    font-size: 34px;
    font-style: italic;
    text-transform: uppercase;
    margin-right: 10px;

    @media only screen and (max-width: 768px){
        font-size:18px;
  }

`;
export const CopyContainer = styled.div`
    display: flex;
    align-items: center;
`;
export const CopyP = styled.p`
    font-family: 'Roboto', sans-serif;
    font-size: 18px;

    @media only screen and (max-width: 768px){
        font-size: 12px;
  }
`;
