import styled from "styled-components";
import {Link} from "react-router-dom";

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #353535;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '100%' : '0%')};
    left: ${({isOpen}) => (isOpen? '0' :'100%')};
`;

export const MenuContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: white;
    @media only screen and (max-width: 768px){
        margin-top: 100px;
        align-items:center;
        flex-direction: column;
  }
`;

export const NavMenu = styled.div`
    height: 500px;
    margin: 100px;
    @media only screen and (max-width: 768px){
        margin:0;
  }
`;

export const NavTitle = styled.h1`
    font-size:36px;
    margin-bottom: 30px;

    @media only screen and (max-width: 768px){
        align-items:center;
        text-align: center;
  }
    
`;

export const NavLinks = styled(Link)`
    display:flex;
    align-items: center;
    justify-content: left;
    text-decoration: none;
    list-style: none;
    font-size: 48px;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    cursor: pointer;
    margin-bottom: 10px;
    color:white;

    &:hover{
        color: #BEBEBE;
        transition: 0.2s ease-in-out;
    }

    @media only screen and (max-width: 768px){
        justify-content: center;
  }
`;

export const KontaktInfo = styled.div`
    height: 500px;
    margin: 100px;
    @media only screen and (max-width: 768px){
        margin:0;
        padding: 10px;
        border-left: none;
        border-top: 1px solid white;
        align-items: center;
  }
`;

export const KontaktTitle = styled.h1`
    font-size:36px;
    margin-bottom:30px;

    @media only screen and (max-width: 768px){
        text-align: center;
  }
`;

export const KontaktText = styled.p`
    font-size:24px;
    margin-bottom: 10px;
    @media only screen and (max-width: 768px){
        text-align: center;
  }
`;

export const KontaktSocial = styled.div`
    margin-top: 25px;
    display: flex;

    @media only screen and (max-width: 768px){
        justify-content: center;
  }
`;

export const SocialLink = styled.div`
    margin: 10px;
    &:hover{
        cursor: pointer;
    }  
    @media only screen and (max-width: 768px){
  }
`;