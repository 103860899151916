import styled from "styled-components";
import { Link} from "react-router-dom";

export const Nav = styled.nav`
  background: transparent;
  position: absolute;
  justify-content: center;
  align-items: center;
  width: 100%;
  top: 0; 
  `;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 120px;
  width: 100%;
  padding: 0 24px;

  @media only screen and (max-width: 768px){
    height: 80px;
    padding: 0 5px;
  }
  `;

export const NavLogoWeb = styled(Link)`
  justify-self: flex-start;
  display: flex;
  align-items: center;
  margin-left: 24px;
  z-index: 999;

  @media only screen and (max-width: 768px){
    display:none;
  }
`;

export const NavLogoMobile = styled(Link)`
  display: none;

  @media only screen and (max-width: 768px){
    justify-self: flex-start;
    display: flex;
    align-items: center;
    z-index:999;
    margin-left: 24px;
  }
`;

export const NavButton = styled.div`
  justify-self: flex-end;
  display: flex;
  align-items: center;
  margin-right: 24px;
  position: sticky;
  z-index: 999;

  &:hover{
        cursor: pointer;
    }  

  transition: 0.3s ease-in-out;
  @media only screen and (max-width: 768px){

  }
`;